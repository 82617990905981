<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-form-field class="filter-select">
            <input matInput placeholder="Utility Name or EAGLE-I ID" (keyup)="filterData($event.target.value)" />
            <fa-icon matSuffix icon="search"></fa-icon>
        </mat-form-field>

        <eaglei-location-filter
            [newDesign]="true"
            (locations)="changeSelectedLocations($event)"
            [defaultSelection]="selectedStates"
            [selectAllOption]="true"></eaglei-location-filter>

        <mat-form-field class="filter-select">
            <mat-label>Filter Script Status</mat-label>
            <mat-select placeholder="Filter Script Status" [(ngModel)]="selectedStatus" (selectionChange)="updateStatusFilter($event)">
                <mat-option [value]="'all'">All</mat-option>
                <mat-option [value]="'enabled'">Enabled</mat-option>
                <mat-option [value]="'disabled'">Disabled</mat-option>
            </mat-select>
        </mat-form-field>

        <eaglei-date-range (change)="updateDates($event)" [min]="undefined" [newDesign]="true"> </eaglei-date-range>
    </section>

    <button
        mat-stroked-button
        matTooltip="Export"
        matTooltipPosition="above"
        matTooltipShowDelay="250"
        [matMenuTriggerFor]="menu"
        [matMenuTriggerFor]="menu">
        <fa-icon icon="file-export"></fa-icon>
    </button>
</div>

<div class="report-data">
    <ng-container [ngTemplateOutlet]="lineChart"></ng-container>
    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<ng-template #lineChart>
    <mat-card class="chart">
        <mat-card-subtitle>
            <span *ngIf="expanded !== undefined">Showing Outages for {{ expanded ? expanded.utilityName : '' }}</span>

            <i
                *ngIf="expanded !== undefined"
                class="fa fa-fw fa-info-circle info"
                (click)="viewUtilScripts(expanded)"
                matTooltipPosition="right"
                matTooltipShowDelay="150"
                matTooltipHideDelay="450"
                [matTooltip]="expanded.url"></i>
        </mat-card-subtitle>
        <mat-card-content [class.invisible]="showMask || loading">
            <div class="chart-target">
                <div #chartTarget class="chart-target"></div>
            </div>
        </mat-card-content>

        <div *ngIf="showMask || loading" class="auto-mask hide-mask">
            <div class="mask-message">
                {{ getMaskText() }}
                <mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
            </div>
        </div>
    </mat-card>
</ng-template>

<ng-template #reportTable>
    <mat-card>
        <mat-card-content>
            <div class="table-container">
                <table
                    mat-table
                    class="test-design"
                    style="overflow: hidden"
                    [dataSource]="dataSource"
                    matSort
                    [matSortDisableClear]="true"
                    matSortActive="last_outage_date"
                    matSortDirection="desc">
                    <ng-container matColumnDef="eagle_id">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-column">
                            EAGLE-I&trade; ID
                            <eaglei-table-sort-header [columnName]="'eagle_id'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="small-column">{{ utility.utilityId }}</td>
                    </ng-container>

                    <ng-container matColumnDef="utility_name">
                        <th mat-header-cell *matHeaderCellDef class="utility-name" mat-sort-header>
                            Utility Name
                            <eaglei-table-sort-header [columnName]="'utility_name'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="utility-name">
                            <p class="ellipsis">{{ utility.utilityName }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="acronym">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Acronym
                            <eaglei-table-sort-header [columnName]="'acronym'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            <p class="ellipsis">{{ utility.acronym || 'Not Available' }}</p>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="covered_customers">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Covered Customers
                            <eaglei-table-sort-header [columnName]="'covered_customers'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            {{ utility.coveredCustomers | eagleiNumber }}
                            <span class="county-customer"> {{ getCountyCustomerText(utility) }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            State/Territory
                            <eaglei-table-sort-header [columnName]="'state'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            {{ utility.stateAbbreviation }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="last_outage_date">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>
                            Last Outage Date
                            <eaglei-table-sort-header [columnName]="'last_outage_date'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility">
                            {{ utility.lastOutage | momentDate }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="script_status">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-col">
                            Script Status
                            <eaglei-table-sort-header [columnName]="'script_status'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="small-col">
                            {{ utility.activeStatus ? 'Enabled' : 'Disabled' }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="resolution">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-col">
                            Resolution
                            <eaglei-table-sort-header [columnName]="'resolution'" [sort]="sort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let utility" class="small-col">
                            {{ getResolution(utility.resolution) }}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="url">
                        <th mat-header-cell *matHeaderCellDef class="small-col center">Outage Map URL</th>
                        <td mat-cell *matCellDef="let utility" class="small-col center">
                            <a
                                *ngIf="getOutageURL(utility) && getOutageURL(utility).length > 0"
                                [href]="getOutageURL(utility)"
                                target="_blank"
                                (click)="stopPropagation($event)"
                                matTooltipPosition="below"
                                matTooltipShowDelay="150"
                                matTooltipHideDelay="450"
                                [matTooltip]="getOutageURL(utility)">
                                <fa-icon icon="external-link-alt" class="clickable"></fa-icon>
                            </a>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="twitter_link">
                        <th mat-header-cell *matHeaderCellDef class="small-col center">Twitter Link</th>
                        <td mat-cell *matCellDef="let utility" class="small-col center">
                            <a
                                *ngIf="utility.twitterURL && utility.twitterURL.length > 0"
                                [href]="utility.twitterURL"
                                target="_blank"
                                (click)="stopPropagation($event)"
                                matTooltipPosition="below"
                                matTooltipShowDelay="150"
                                matTooltipHideDelay="450"
                                [matTooltip]="utility.twitterURL">
                                <fa-icon [icon]="['fab', 'twitter']"></fa-icon>
                            </a>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: columnNames; let odd = odd"
                        [class.selected]="expanded === row"
                        (click)="toggleExpansion(row)"
                        class="clickable"></tr>
                </table>
            </div>
            <div>
                <div *ngIf="dataSource && !(dataSource.filteredData.length > 0)" class="table-mask">
                    <b>No Data Matching the Filters</b>
                </div>
                <mat-paginator #paginator [pageSizeOptions]="[25, 50, 75, 100]" showFirstLastButtons> </mat-paginator>
            </div>

            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<mat-menu #menu="matMenu">
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
