<!--Report Filters-->
<div class="filter-bar ignore-last">
    <section class="filters">
        <mat-button-toggle-group [value]="filters.aggregationLevel" (change)="changeAggregation($event)">
            <mat-button-toggle *ngFor="let level of aggregationLevels" [value]="level">{{ level | aggregationLevel }}</mat-button-toggle>
        </mat-button-toggle-group>

        <eaglei-location-filter
            [selectAllOption]="true"
            (locations)="changeSelectedLocations($event)"
            [defaultSelection]="filters.states"></eaglei-location-filter>

        <eaglei-date-range
            [newDesign]="true"
            [singlePicker]="true"
            [showTime]="false"
            [start]="chartStartDate"
            (change)="changeChartDate($event)"
            [startPlaceholder]="'Chart Start Date'"
            [min]="minDate">
        </eaglei-date-range>
    </section>

    <div>
        <button
            mat-stroked-button
            (click)="openEmailModal()"
            class="request-email"
            *ngIf="!onMobile()"
            matTooltip="Periodically send current selected report data for up to 7 days"
            matTooltipShowDelay="250"
            matTooltipPosition="above">
            <fa-icon icon="envelope"></fa-icon>
        </button>

        <button mat-stroked-button matTooltip="Export" matTooltipPosition="above" matTooltipShowDelay="250" [matMenuTriggerFor]="menu">
            <fa-icon icon="file-export"></fa-icon>
        </button>
    </div>
</div>

<div class="filters extra-row">
    <mat-chip-listbox>
        <mat-chip [class.selected]="showOverrideColor" (click)="toggleOverrideColor(!showOverrideColor)">
            <div>
                <fa-icon [icon]="['far', showOverrideColor ? 'eye' : 'eye-slash']"></fa-icon>
                <span class="chip-text">Overrides</span>
            </div>
        </mat-chip>

        <mat-chip [class.selected]="update.autoUpdate" (click)="toggleAutoUpdate()">
            <div>
                <fa-icon icon="history"></fa-icon>
                <span class="chip-text">
                    Auto refresh report
                    <span *ngIf="update.autoUpdate">(Next refresh at {{ update.nextUpdate | momentDate : 'h:mm A' }} )</span>
                </span>
            </div>
        </mat-chip>
    </mat-chip-listbox>
</div>

<div class="filter-chips">
    <span class="filter-text">Filters: </span>
    <eaglei-filter-chip-list [states]="filters.states" (change)="changeSelectedLocations($event)"></eaglei-filter-chip-list>
</div>

<!--Report Layout-->
<div class="report-data">
    <div class="row">
        <ng-container [ngTemplateOutlet]="barChart"></ng-container>
        <ng-container [ngTemplateOutlet]="nomMap"></ng-container>
    </div>

    <ng-container [ngTemplateOutlet]="reportTable"></ng-container>
</div>

<!--Chart-->
<ng-template #barChart>
    <mat-card class="chart">
        <mat-card-content>
            <div class="chart-target">
                <eaglei-nom-chart
                    [aggregationLevel]="filters.aggregationLevel"
                    [startDate]="chartStartDate"
                    [endDate]="filters.runStartTime"
                    [states]="filters.states">
                </eaglei-nom-chart>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<!--Map-->
<ng-template #nomMap>
    <section class="map-section">
        <mat-card class="map">
            <mat-card-content>
                <eaglei-leaflet-map [mapOptions]="mapOptions"></eaglei-leaflet-map>
            </mat-card-content>
            <mat-card-footer>
                <eaglei-nom-legend (hideOverrides)="layerSource.redraw()"></eaglei-nom-legend>
            </mat-card-footer>
        </mat-card>
    </section>
</ng-template>

<!--Table-->
<ng-template #reportTable>
    <mat-card>
        <mat-card-content [class.invisible]="showMask">
            <div class="table-container">
                <table
                    #tableSort="matSort"
                    mat-table
                    matSort
                    matSortDisableClear
                    matSortActive="customersOut"
                    matSortDirection="desc"
                    [dataSource]="$data()"
                    (matSortChange)="handleSort($event)">
                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="small-col">
                            {{ filters.aggregationLevel | aggregationLevel }}
                            <eaglei-table-sort-header [columnName]="'name'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info" class="small-col">
                            <span> {{ info.zipCode ?? info.countyName ?? (info.stateName | stateName) }}</span>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="fips">
                        <th class="small-col right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>FIPS Code</span>
                                <eaglei-table-sort-header [columnName]="'fips'" [sort]="tableSort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let info" class="right-cell small-col">
                            <div>
                                <span>{{ info.countyFIPSCode ?? info.fipsCode ?? 'Not Available' }}</span>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="customersOut">
                        <th class="right-cell" mat-header-cell mat-sort-header *matHeaderCellDef>
                            <div class="sort-header">
                                <span>Customers Out</span>
                                <eaglei-table-sort-header [columnName]="'customersOut'" [sort]="tableSort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td
                            class="right-cell"
                            mat-cell
                            *matCellDef="let info"
                            [style.color]="info.currentOutageHasOverrideData && showOverrideColor ? overrideColor : '#707070'">
                            <div>
                                {{ info.currentOutage | eagleiNumber }}
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="totalCustomers">
                        <th class="right-cell" mat-header-cell mat-sort-header *matHeaderCellDef>
                            <div class="sort-header">
                                <span>Total Customers</span>
                                <eaglei-table-sort-header [columnName]="'totalCustomers'" [sort]="tableSort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td class="right-cell" mat-cell *matCellDef="let info">
                            <div>
                                <span *ngIf="info.coveredCustomers; else noCustomers">
                                    {{ info.coveredCustomers | eagleiNumber }}
                                    <span class="county-customer">{{ info.modelCount ? '(Modeled)' : '(Collected)' }}</span>
                                </span>
                                <ng-template #noCustomers> Not Available </ng-template>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="percentOut">
                        <th class="right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>Percent Out</span>
                                <eaglei-table-sort-header [columnName]="'percentOut'" [sort]="tableSort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td class="right-cell" mat-cell *matCellDef="let info">
                            <div>
                                <span *ngIf="info.coveredCustomers; else unavailable">
                                    {{ info.currentOutage / info.coveredCustomers | percent : '1.2-2' }}
                                </span>
                                <ng-template #unavailable>
                                    <span>Not Available</span>
                                </ng-template>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="state">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header class="icon-col">
                            State/Territory
                            <eaglei-table-sort-header [columnName]="'state'" [sort]="tableSort"></eaglei-table-sort-header>
                        </th>
                        <td mat-cell *matCellDef="let info">{{ info.stateName | stateName }}</td>
                    </ng-container>

                    <ng-container matColumnDef="lastHourMaxOutage">
                        <th class="right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>Last Hour Max Outage</span>
                                <eaglei-table-sort-header [columnName]="'lastHourMaxOutage'" [sort]="tableSort"></eaglei-table-sort-header>
                            </div>
                        </th>
                        <td
                            class="right-cell"
                            mat-cell
                            *matCellDef="let info"
                            (click)="hourTooltip.toggle()"
                            [style.color]="info.maxOutage1HasOverrideData && showOverrideColor ? overrideColor : '#707070'">
                            <div>
                                <span> {{ info.maxOutage1 | eagleiNumber }} </span>
                                <img
                                    src="dist/browser/images/icons/calendar.svg"
                                    alt="calendar"
                                    class="table-calendar"
                                    #hourTooltip="matTooltip"
                                    [matTooltip]="info.maxOutage1RunStartTime | momentDate"
                                    matTooltipPosition="above"
                                    matTooltipShowDelay="250" />
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lastDayMaxOutage">
                        <th class="right-cell" mat-header-cell *matHeaderCellDef mat-sort-header>
                            <div class="sort-header">
                                <span>Last Day Max Outage</span>
                                <eaglei-table-sort-header [columnName]="'lastDayMaxOutage'" [sort]="tableSort"></eaglei-table-sort-header>
                            </div>
                        </th>

                        <td
                            class="right-cell"
                            mat-cell
                            *matCellDef="let info"
                            (click)="dayTooltip.toggle()"
                            [style.color]="info.maxOutage24HasOverrideData && showOverrideColor ? overrideColor : '#707070'">
                            <div>
                                <span> {{ info.maxOutage24 | eagleiNumber }} </span>
                                <img
                                    src="dist/browser/images/icons/calendar.svg"
                                    alt="calendar"
                                    class="table-calendar"
                                    #dayTooltip="matTooltip"
                                    [matTooltip]="info.maxOutage24RunStartTime | momentDate"
                                    matTooltipPosition="above"
                                    matTooltipShowDelay="250" />
                            </div>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns; let odd = odd"
                        (click)="openUtilityModal(row)"
                        [class.clickable]="filters.aggregationLevel.name === 'county'"></tr>
                </table>
                <mat-paginator
                    showFirstLastButtons
                    [length]="$totalRows()"
                    [pageIndex]="$pageIndex()"
                    [pageSize]="$pageSize()"
                    [pageSizeOptions]="[25, 50, 75, 100]"
                    (page)="handlePaginate($event)"></mat-paginator>
            </div>
            <div class="table-attribution">
                <a (click)="reportService.openAttribution(attributionUrl)" [innerHTML]="attributionTitle | sanitize"></a>
            </div>
        </mat-card-content>
    </mat-card>
</ng-template>

<!--Export Menu-->
<mat-menu #menu="matMenu" overlapTrigger>
    <button (click)="exportTable()" mat-menu-item>Export Table As CSV</button>
    <button (click)="exportChartAsCsv()" mat-menu-item>Export Chart As CSV</button>
    <button (click)="exportChart()" mat-menu-item>Export Chart As PNG</button>
</mat-menu>
